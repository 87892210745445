import {
    Datagrid,
    FunctionField,
    NumberField,
    ReferenceField,
    TextField,
} from 'react-admin';
import {DashboardCampaignProps} from '../../types/dashboard-campaign-props.type';
import DashboardListWrapper from '../common/dashboardListWrapper';
import ProgressWithLabel from '../common/progressBarWithLabel';
import {
    completionRatePercentage,
    completionRateText,
} from '../../utility/helpers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';

const DashboardCampaigns = (props: DashboardCampaignProps) => {
    const {strategyType, userId, cardTitle} = props;

    return (
        <DashboardListWrapper
            cardTitle={cardTitle}
            title=" "
            resource="v1_Campaign"
            filter={{
                owner_id: userId,
                'Strategy#CampaignType#id': strategyType,
                statecode: 0,
            }}
            searchFields={'new_name,Project#new_name@_ilike'}
        >
            <Datagrid bulkActionButtons={false}>
                <LinkToNewTab propertyName="id" resource="v1_Campaign" />
                <LinkToRecordField source="id" label="Campaign ID" redirectToShow={false}/>
                <TextField label="Name" source="new_name" />
                <ReferenceField
                    label="Project"
                    source="project_id"
                    reference="v1_Project"
                    link="edit"
                >
                    <TextField source="new_name" />
                </ReferenceField>
                <NumberField
                    source="new_MonthlyTarget"
                    label="Monthly Target"
                />
                <FunctionField
                    label="Completion Rate Last Month"
                    sortBy="CompletionRateLastMonth"
                    render={(record: {
                        LastMonthPosts: number;
                        new_MonthlyTarget: number;
                    }) => {
                        return (
                            <ProgressWithLabel
                                value={completionRatePercentage(
                                    record.LastMonthPosts,
                                    record.new_MonthlyTarget
                                )}
                                displayValue={completionRateText(
                                    record.LastMonthPosts,
                                    record.new_MonthlyTarget
                                )}
                            />
                        );
                    }}
                />
                <FunctionField
                    label="Completion Rate This Month"
                    sortBy="CompletionRateThisMonth"
                    source="CompletionRateThisMonth"
                    render={(record: {
                        ThisMonthPosts: number;
                        new_MonthlyTarget: number;
                    }) => {
                        return (
                            <ProgressWithLabel
                                value={completionRatePercentage(
                                    record.ThisMonthPosts,
                                    record.new_MonthlyTarget
                                )}
                                displayValue={completionRateText(
                                    record.ThisMonthPosts,
                                    record.new_MonthlyTarget
                                )}
                            />
                        );
                    }}
                />
                <ReferenceField
                    label="Importance"
                    source="new_Priority"
                    reference="v1_CampaignPriority"
                    link={false}
                >
                    <TextField source="value" />
                </ReferenceField>
                <NumberField source="RequestedPosts" label="Requested" />
                <NumberField source="OrderedPosts" label="Ordered" />
                <NumberField source="ApprovedPosts" label="Approved" />
                <NumberField
                    source="WaitingApprovalPosts"
                    label="Waiting Approval"
                />
                <NumberField
                    source="SentToPublisherPosts"
                    label="Sent To Publisher"
                />
                <NumberField source="DeclinedPosts" label="Declined" />
                <NumberField source="LivePosts" label="Live Posts" />
                <NumberField source="PoolPosts" label="Pool" />
            </Datagrid>
        </DashboardListWrapper>
    );
};
export default DashboardCampaigns;
