import {useEffect} from 'react';
import buildHasuraProvider from 'ra-data-hasura';
import customBuildFields from '../queries/custom-build-queries';
import {clientOptions} from './hasuraConfig';

const useHasuraDataProvider = (setStateProvider: any) => {
    useEffect(() => {
        const buildDataProvider = async () => {
            const dataProviderHasura = await buildHasuraProvider(
                {clientOptions},
                {
                    buildFields: customBuildFields,
                }
            );
            setStateProvider(dataProviderHasura);
        };
        buildDataProvider();

    }, [setStateProvider]);
};
export default useHasuraDataProvider;
