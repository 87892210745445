import {IResourceConfigItem} from '../models/resource-config.model';

export const resourceList: IResourceConfigItem[] = [
    {
        table: 'v1_AccountBase',
        componentName: 'company',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_SalesOrderBase',
        componentName: 'affiliateOrder',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_new_affordertypeBase',
        componentName: 'affiliateOrderCategory',
        hasList: true,
        hasShow: false,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_new_trackerBase',
        componentName: 'tracker',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_new_brandBase',
        componentName: 'brand',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
	{
		table: 'v1_TrackerTags',
		componentName: 'trackerTag',
		hasList: true,
		hasShow: true,
		hasCreate: true,
		hasEdit: true,
	},
    {
        table: 'v1_InvoiceBase',
        componentName: 'invoice',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_new_receiptBase',
        componentName: 'receipt',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_new_POBase',
        componentName: 'purchaseOrder',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_ServiceTypes',
        componentName: 'paymentCategory',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_new_languagesBase',
        componentName: 'language',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_new_countryBase',
        componentName: 'country',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_process',
        componentName: 'process',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_ContactBase',
        componentName: 'writer',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_Project',
        componentName: 'project',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_CampaignStrategy',
        componentName: 'strategy',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_Offer',
        componentName: 'offer',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_Website',
        componentName: 'website',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_WebsiteTopics',
        componentName: 'websiteTopic',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_Suppliers',
        componentName: 'supplier',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_SystemUserBase',
        componentName: 'user',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_Posts',
        componentName: 'post',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_Tags',
        componentName: 'tag',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_Campaign',
        componentName: 'campaign',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_Teams',
        componentName: 'team',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_Report',
        componentName: 'report',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_Page',
        componentName: 'page',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
    {
        table: 'v1_LandingPages',
        componentName: 'landingPage',
        hasList: true,
        hasShow: true,
        hasCreate: true,
        hasEdit: true,
    },
];
