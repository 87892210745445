import {Maybe, V1_TransactionCurrencyBase} from '../../generated/types';

export const buildPath = (compName: string, type: string) => {
    return `${compName}/${compName}${type}`;
};

export const getLastMonthDate = () =>
    `${new Date().getMonth()}/01/${new Date().getFullYear()}`;

export const getThisMonthDate = () =>
    `${new Date().getMonth() + 1}/01/${new Date().getFullYear()}`;

export const getNextMonthDate = () =>
    `${new Date().getMonth() + 2}/01/${new Date().getFullYear()}`;

export const parseDate = (dateString: string) => {
    if (!dateString) {
        return '';
    }
    return Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }).format(new Date(dateString));
};

export const parsePrice = (
    currencies: V1_TransactionCurrencyBase[],
    currencyId: Maybe<number> | undefined,
    amount: Maybe<number>
) => {
    return `${
        currencies?.find((c) => c.id === currencyId)?.CurrencySymbol
    }${amount}`;
};

export const completionRatePercentage = (
    currentVal: number = 0,
    monthlyTarget: number = 0
): number => {
    const safeCurrentVal: number = currentVal ?? 0;
    let safeMonthlyTarget: number = monthlyTarget ?? 0;

    if (safeCurrentVal > 0 && safeMonthlyTarget === 0) safeMonthlyTarget = 1;

    return (safeCurrentVal / safeMonthlyTarget) * 100 || 0;
};

export const completionRateText = (
    currentVal: number,
    monthlyTarget: number
) => {
    const percentage = completionRatePercentage(
        currentVal,
        monthlyTarget
    ).toFixed(2);
    return `${percentage}% (${currentVal}/${monthlyTarget})`;
};

export const completionRateTextWithoutFraction = (
    currentVal: number,
    monthlyTarget: number
) => {
    const percentage = completionRatePercentage(
        currentVal,
        monthlyTarget
    ).toFixed(2);
    return `${percentage}%`;
};
