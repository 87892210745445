import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import {IfCanAccess} from '@react-admin/ra-rbac/esm/src/IfCanAccess';
import {Loading, useStore} from 'react-admin';
import useGetUserId from '../../hooks/useGetUserId';
import AutocompleteWrapper from '../common/autocompleteWrapper';
import DashboardCampaigns from '../generic/dashboardCampaigns';
import DashboardListWrapper from '../common/dashboardListWrapper';
import DeclinedPostsFields, {
    declinedPostsSearchFields,
} from './pmDashboardDeclinedPostsFields';
import OrderedPostsFields, {
    orderedPostsSearchFields,
} from './pmDashboardOrderedPostsFields';
import PostsToApproveFields, {
    postsToApproveSearchFields,
} from './pmDashboardPostsToApproveFields';
import PostsToUploadFields, {
    postsToUploadSearchFields,
} from './pmDashboardPostsToUploadFields';
import RequestedContentFields, {
    requestedContentSearchFields,
} from './pmDashboardRequestedContentFields';
import {useGetActiveUsers} from '../../hooks/useGetActiveUsers';
import {
    CONTENT_STATUS,
    POST_STATUS,
    STRATEGY_TYPE,
} from '../../config/statuses';

const PmDashboardList = (props: any) => {
    const userId = useGetUserId();
    const [selectedUserId, setSelectedUserId] = useStore(
        'pmDashboardSelectedUser',
        userId
    );
    const {data: activeUsers, isLoading} = useGetActiveUsers();
    return (
        <Grid container display="flex" flexDirection="column" sx={{mt: '20px'}}>
            <Grid item alignSelf="flex-end">
                {isLoading ? (
                    <Loading />
                ) : (
                    <IfCanAccess resource={'pmDashboard'} action="selectUser">
                        <AutocompleteWrapper
                            disableClear={true}
                            styles={{width: 300}}
                            selectedOption={selectedUserId}
                            label="User"
                            options={activeUsers}
                            labelProp="name"
                            onOptionSelected={setSelectedUserId}
                            isRequired={false}
                        />
                    </IfCanAccess>
                )}
            </Grid>
            <Grid item>
                <Card {...props}>
                    <DashboardListWrapper
                        cardTitle="Posts to Approve"
                        title=" "
                        resource="v1_Posts"
                        filter={{
                            approver_id: selectedUserId,
                            new_ContentOrderStatus:
                                CONTENT_STATUS.WAITING_APPROVAL,
                            'new_PostStatus@_nin': [POST_STATUS.POSTED],
                            statecode: 0,
                        }}
                        searchFields={postsToApproveSearchFields}
                        sort={{field: 'new_SenttoApproval', order: 'DESC'}}
                    >
                        <PostsToApproveFields />
                    </DashboardListWrapper>
                    <DashboardListWrapper
                        cardTitle="Requested Content"
                        title="PM Dashboard"
                        resource="v1_Posts"
                        filter={{
                            new_ContentOrderStatus:
                                CONTENT_STATUS.CONTENT_REQUEST,
                            requestedfrom_id: selectedUserId,
                            'new_PostStatus@_nin': [POST_STATUS.POSTED],
                            statecode: 0,
                        }}
                        searchFields={requestedContentSearchFields}
                        sort={{field: 'new_RequestedOn', order: 'DESC'}}
                    >
                        <RequestedContentFields />
                    </DashboardListWrapper>
                    <DashboardListWrapper
                        cardTitle="Declined Posts"
                        title=" "
                        resource="v1_Posts"
                        filter={{
                            declinedby_id: selectedUserId,
                            new_ContentOrderStatus: CONTENT_STATUS.DECLINED,
                            approver_id: selectedUserId,
                            'new_PostStatus@_nin': [POST_STATUS.POSTED],
                            statecode: 0,
                        }}
                        searchFields={declinedPostsSearchFields}
                        sort={{field: 'new_DeclineDate', order: 'DESC'}}
                    >
                        <DeclinedPostsFields />
                    </DashboardListWrapper>
                    <DashboardListWrapper
                        cardTitle="Posts to Upload"
                        title=" "
                        resource="v1_Posts"
                        filter={{
                            createdby_id: selectedUserId,
                            new_ContentOrderStatus: CONTENT_STATUS.APPROVED,
                            'new_PostStatus@_nin': [POST_STATUS.POSTED],
                            'Campaign#Owner#id': selectedUserId,
                            statecode: 0,
                        }}
                        searchFields={postsToUploadSearchFields}
                        sort={{field: 'new_DateApproved', order: 'DESC'}}
                    >
                        <PostsToUploadFields />
                    </DashboardListWrapper>
                    <DashboardListWrapper
                        cardTitle="Ordered Posts"
                        title=" "
                        resource="v1_Posts"
                        filter={{
                            orderedby_id: selectedUserId,
                            new_ContentOrderStatus: CONTENT_STATUS.ORDERED,
                            'new_PostStatus@_nin': [POST_STATUS.POSTED],
                            statecode: 0,
                        }}
                        searchFields={orderedPostsSearchFields}
                        sort={{field: 'CreatedOn', order: 'DESC'}}
                    >
                        <OrderedPostsFields />
                    </DashboardListWrapper>
                    <DashboardCampaigns
                        cardTitle="On Site Campaigns"
                        userId={selectedUserId}
                        strategyType={STRATEGY_TYPE.ON_SITE_CONTENT}
                    />
                    <DashboardCampaigns
                        cardTitle="Link Building Campaigns"
                        userId={selectedUserId}
                        strategyType={STRATEGY_TYPE.LINK_BUILDING}
                    />
                    <DashboardCampaigns
                        cardTitle="Social Campaigns"
                        userId={selectedUserId}
                        strategyType={STRATEGY_TYPE.SOCIAL}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};

export default PmDashboardList;
