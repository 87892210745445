import _ from 'lodash';
import {EditButton, List, NumberField, TextField} from 'react-admin';
import {USER_ROLES} from '../../config/roles';
import useRestrictBulkSelection from '../../hooks/useRestrictBulkSelection';
import {DatagridConfigurableBySavedQuery} from '../common/datagridConfigurableBySavedQuery';
import {ListActions} from '../common/listActions';

export const userHasReportRights = (
	userRoles: string[],
	reportRoles?: string[]
): boolean => {
	return (
		userRoles.indexOf(USER_ROLES.ADMIN) > -1 ||
		userRoles.indexOf(USER_ROLES.AFFILIATE_MANAGER) > -1 ||
		_.intersection(userRoles, reportRoles).length > 0
	);
};

const ReportList = (props: object) => {
	const restrictBulkSelection = useRestrictBulkSelection({
		resource: 'Report',
	});

	return (
		<List
			title="Reports"
			{...props}
			actions={<ListActions/>}
			perPage={25}
		>
			<DatagridConfigurableBySavedQuery
				rowClick="show"
				bulkActionButtons={restrictBulkSelection}
			>
				<EditButton className="MuiButton--without-label"/>
				<TextField label="Name" source="name"/>
				<NumberField label="Metabase Id" source="metabase_id"/>
			</DatagridConfigurableBySavedQuery>
		</List>
	);
};

export default ReportList;
