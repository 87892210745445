import {downloadCSV, Exporter} from 'react-admin';
import {store} from './storeWrapper';
import pick from 'lodash/pick';
import jsonExport from 'jsonexport/dist';

export const getConfigurableExporter = (preferenceKey: string): Exporter => {
    return (data, _, __, resource) => {
        const fieldsConfiguration: FieldConfiguration[] = store.getItem(
            `preferences.${preferenceKey}.availableColumns`
        );
        const displayedColumns: string[] = store.getItem(
            `preferences.${preferenceKey}.columns`
        );

        const sortedAndFilteredColumns: FieldConfiguration[] = [];
        displayedColumns.forEach((columnIndex) => {
            const fieldConfiguration = fieldsConfiguration.find(
                (config) => config.index === columnIndex
            );
            if (fieldConfiguration)
                sortedAndFilteredColumns.push(fieldConfiguration);
        });

        const options = {
            headers: sortedAndFilteredColumns.map((f) => f.source as string),
            rename: sortedAndFilteredColumns.map((f) => f.label),
        };

        const filteredData = data.map((item: any) =>
            pick(item, options.headers)
        );

        return jsonExport(filteredData, options, (err: any, csv: string) =>
            downloadCSV(csv, resource as string)
        );
    };
};

export type FieldConfiguration = {
  index: string;
  source?: string;
  label: string;
};
