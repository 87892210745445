import gql from 'graphql-tag';

export const GET_LIST_USER_ROLES = gql`
  {
    id
    name
    Users_Roles {
      user_id
    }
    Contacts_Roles {
      contact_id
    }
    Report_Roles {
      role_id
    }
  }
`;
