import {IMenuItem} from '../models/menu-item.model';
import {
    AccountBalanceWallet,
    AccountCircle,
    AddCard,
    ArrowForward,
    AutoStories,
    Book,
    Business,
    CircleNotifications,
    Computer,
    CurrencyExchange,
    Dvr,
    EmojiEvents,
    EmojiObjects,
    FolderOpen,
    Groups,
    Hub,
    Language,
    Memory,
    Newspaper,
    PointOfSale,
    Public,
    ReceiptLong,
    RocketLaunch,
    Savings,
    Sell,
    Stars,
    Translate,
    Troubleshoot,
    UploadFile,
} from '@mui/icons-material';

export const menuItemsList: IMenuItem[] = [
    {
        name: 'Dashboards',
        resource: '',
        to: '/',
        label: 'Dashboards',

        children: [
            {
                name: '',
                resource: 'pmDashboard',
                to: '/pm-dashboard',
                label: 'PM',
                icon: <Dvr color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'outreachDashboard',
                to: '/outreach-dashboard',
                label: 'Outreach',
                icon: <Hub color="secondary" fontSize="small" />,
            },
        ],
    },
    {
        name: 'Content',
        resource: '',
        to: '/',
        label: 'Content',
        children: [
            {
                name: '',
                resource: 'v1_Project',
                to: {
                    pathname: '/v1_Project',
                    search: `filter=${JSON.stringify({statecode: 0})}`,
                },
                label: 'Projects',
                icon: <RocketLaunch color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Campaign',
                to: {
                    pathname: '/v1_Campaign',
                    search: `filter=${JSON.stringify({statecode: 0})}`,
                },
                label: 'Campaigns',
                icon: <Stars color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Posts',
                to: {
                    pathname: '/v1_Posts',
                    search: `filter=${JSON.stringify({
                        statecode: 0,
                        'Campaign%23statecode': 0,
                    })}`,
                },
                label: 'Posts',
                icon: <Book color="secondary" fontSize="small" />,
            },

            {
                name: '',
                resource: 'v1_ContactBase',
                to: {
                    pathname: '/v1_ContactBase',
                    search: `filter=${JSON.stringify({
                        new_Contacttype: 100000000,
                        StateCode: 0,
                    })}`,
                },
                label: 'Writers',
                icon: <AutoStories color="secondary" fontSize="small" />,
            },
        ],
    },
    {
        name: 'Outreach',
        resource: '',
        to: '/',
        label: 'Outreach',
        children: [
            {
                name: '',
                resource: 'v1_Suppliers',
                to: {
                    pathname: '/v1_Suppliers',
                    search: `filter=${JSON.stringify({
                        StateCode: 0,
                    })}`,
                },
                label: 'Suppliers',
                icon: <EmojiObjects color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Offer',
                to: {
                    pathname: '/v1_Offer',
                },
                label: 'Offers',
                icon: (
                    <AccountBalanceWallet color="secondary" fontSize="small" />
                ),
            },
            {
                name: '',
                resource: 'v1_Website',
                to: {
                    pathname: '/v1_Website',
                    search: `filter=${JSON.stringify({statecode: 0})}`,
                },
                label: 'Websites',
                icon: <Language color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Tags',
                to: '/v1_Tags',
                label: 'Supplier Tags',
                icon: <Sell color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_WebsiteTopics',
                to: '/v1_WebsiteTopics',
                label: 'Website Topics',
                icon: <Computer color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_CampaignStrategy',
                to: {
                    pathname: '/v1_CampaignStrategy',
                    search: `filter=${JSON.stringify({statecode: 0})}`,
                },
                label: 'Strategies',
                icon: <ArrowForward color="secondary" fontSize="small" />,
            },
        ],
    },
    {
        name: 'Affiliate',
        resource: '',
        to: '/',
        label: 'Affiliate',
        children: [
            {
                name: '',
                resource: 'v1_AccountBase',
                to: {
                    pathname: '/v1_AccountBase',
                    search: `filter=${JSON.stringify({StateCode: 0})}`,
                },
                label: 'Companies',
                icon: <Business color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_SalesOrderBase',
                to: {
                    pathname: '/v1_SalesOrderBase',
                    search: `filter=${JSON.stringify({StateCode: 0})}`,
                },
                label: 'Affiliate Orders',
                icon: <AddCard color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_affordertypeBase',
                to: {
                    pathname: '/v1_new_affordertypeBase',
                },
                label: 'AO Categories',
                icon: <FolderOpen color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_trackerBase',
                to: {
                    pathname: '/v1_new_trackerBase',
                    search: `filter=${JSON.stringify({statecode: 0})}`,
                },
                label: 'Trackers',
                icon: (
                    <CircleNotifications color="secondary" fontSize="small" />
                ),
            },
            {
                name: '',
                resource: 'v1_new_brandBase',
                to: {
                    pathname: '/v1_new_brandBase',
                    search: `filter=${JSON.stringify({statecode: 0})}`,
                },
                label: 'Brands',
                icon: <EmojiEvents color="secondary" fontSize="small" />,
            },
	        {
		        name: '',
		        resource: 'v1_TrackerTags',
		        to: {
			        pathname: '/v1_TrackerTags',
		        },
		        label: 'Tracker Tags',
		        icon: <EmojiEvents color="secondary" fontSize="small" />,
	        },
        ],
    },
    {
        name: 'Financials',
        resource: '',
        to: '/',
        label: 'Financials',
        children: [
            {
                name: '',
                resource: 'v1_InvoiceBase',
                to: '/v1_InvoiceBase',
                label: 'Invoices',
                icon: <PointOfSale color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_receiptBase',
                to: '/v1_new_receiptBase',
                label: 'Receipts',
                icon: <ReceiptLong color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_POBase',
                to: '/v1_new_POBase',
                label: 'Purchase Orders',
                icon: <CurrencyExchange color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_ServiceTypes',
                to: '/v1_ServiceTypes',
                label: 'Payment Categories',
                icon: <Savings color="secondary" fontSize="small" />,
            },
        ],
    },
    {
        name: 'Settings',
        resource: '',
        to: '/',
        label: 'Settings',
        children: [
            {
                name: '',
                resource: 'v1_new_languagesBase',
                to: {
                    pathname: '/v1_new_languagesBase',
                    search: `filter=${JSON.stringify({statecode: 0})}`,
                },
                label: 'Languages',
                icon: <Translate color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_new_countryBase',
                to: '/v1_new_countryBase',
                label: 'Countries',
                icon: <Public color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_process',
                to: '/v1_process',
                label: 'Process',
                icon: <Memory color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_SystemUserBase',
                to: '/v1_SystemUserBase',
                label: 'Users',
                icon: <AccountCircle color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Teams',
                to: '/v1_Teams',
                label: 'Teams',
                icon: <Groups color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Report',
                to: '/v1_Report',
                label: 'Report',
                icon: <Troubleshoot color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_Page',
                to: '/v1_Page',
                label: 'Page',
                icon: <UploadFile color="secondary" fontSize="small" />,
            },
            {
                name: '',
                resource: 'v1_LandingPages',
                to: '/v1_LandingPages',
                label: 'Landing Pages',
                icon: <Newspaper color="secondary" fontSize="small" />,
            },
        ],
    },
];
