import {
  CreateParams,
  CreateResult,
  DataProvider,
  GetListParams,
} from 'react-admin';
import appQueryClient from './appQueryClient';

export const eventsLifecycleCallbacks = {
  resource: 'v1_Events',
  beforeGetList: async (params: GetListParams, dataProvider: DataProvider) => {
    const modifiedParams = {
      ...params,
      filter: {
        ...params.filter,
        'payload@_contains': params.filter['payload'],
      },
    };
    delete modifiedParams.filter['payload'];
    return modifiedParams;
  },
  beforeCreate: async (
    params: CreateParams<any>,
    dataProvider: DataProvider
  ) => {
    params.data.author_id = params.data.author.id;
    return params;
  },
  afterCreate: async (result: CreateResult, dataProvider: DataProvider) => {
    appQueryClient.invalidateQueries('v1_Events');
    return result;
  },
};
