export const crmAppLanguageEnglish = {
    'ra-audit-log': {
        record_event: {
            comment: 'New published comment',
            copyscape: '',
        },
    },
    ra: {
        action: {
            edit: '',
        },
    },
    adzz: {
        auth: {
            token_expired: 'Token expired. Please log in again.',
        },
        resources: {
            v1_Posts: {
                fields: {
                    new_ContentOrderStatus: 'Content Status',
                    new_PostStatus: 'Post Status',
                    new_OutreachStatus: 'Outreach Status',
                    new_TextHTMLbody: 'body',
                    writer_id: 'writer',
                    new_name: 'Source URL',
                    approver_id: 'To Be Approved By',
                    requestedfrom_id: 'Requested From',
                    supplier_id: 'Supplier',
                    new_Supplierprice: 'Supplier Price',
                    new_ContentCost: 'Content Cost',
                    new_UploadPrice: 'Upload Price',
                    new_TotalPrice: 'Total Price',
                    PublisherStatus: 'Publisher status',
                    AdvertiserStatus: 'Advertiser status',
                },
            },
        },
    },
};
