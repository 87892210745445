import {Autocomplete, TextField} from '@mui/material';

type AutocompleteWrapperProps = {
    options: any;
    selectedOption?: any;
    label: string;
    value?: any;
    styles?: any;
    labelProp: string;
    onOptionSelected: any;
    isRequired: boolean;
    disableClear: boolean;
};
const AutocompleteWrapper = (props: AutocompleteWrapperProps) => {
    const {
        options,
        selectedOption,
        onOptionSelected,
        label,
        labelProp,
        styles,
        disableClear,
        isRequired,
    } = props;
    return (
        Array.isArray(options) ?
            <Autocomplete
                key={`${selectedOption}-${label}`}
                disableClearable={disableClear}
                sx={styles}
                options={options}
                getOptionLabel={(option) => (option ? option[labelProp] : '')}
                isOptionEqualToValue={(option, value) => {
                    return selectedOption ? option.id === value.id : true;
                }}
                clearOnBlur
                renderInput={(params) => (
                    <TextField {...params} label={label} required={isRequired}/>
                )}
                defaultValue={''}
                value={
                    selectedOption ? options.find((o: any) => o.id === selectedOption) : ''
                }
                onChange={(event, value, reason) =>
                    onOptionSelected(value ? value.id : null, reason)
                }></Autocomplete> : <>Loading...</>
    )
};
export default AutocompleteWrapper;
