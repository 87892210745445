const hasFieldChanged = (
    field: string,
    data: any,
    previousData: any
): boolean => {
    if (data[field] || previousData[field]) {
        if (data[field] !== previousData[field]) {
            return true;
        }
    }
    return false;
};

const auditedFields = [
    'new_ContentOrderStatus',
    'new_PostStatus',
    'new_OutreachStatus',
    'new_TextHTMLbody',
    'writer_id',
    'new_name',
    'approver_id',
    'requestedfrom_id',
    'supplier_id',
    'new_Supplierprice',
    'new_ContentCost',
    'new_UploadPrice',
    'PublisherStatus',
    'AdvertiserStatus',
];

export const shouldAudit = (action: string, ...args: any[]): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_resource, params] = args;
    if (action === 'update') {
        const {data, previousData} = params;
        return auditedFields.some((field) =>
            hasFieldChanged(field, data, previousData)
        );
    }
    return false;
};
