import gql from 'graphql-tag';

export const GET_LIST_OFFERS = gql`
  {
    website_name
    casino_accepted
    created_on
    currency
    follow_link
    id
    link_price
    notes
    supplier_id
    website_id
    currency_id
    Website {
      Language {
        new_name
      }
    }
  }
`;
