export const USER_ROLES = {
    ADMIN: 'Admin',
    AFFILIATE_MANAGER: 'Affiliate Manager',
    FINANCIAL_USER: 'Financial User',
    ONSITE_MANAGER: 'Onsite Manager',
    ONSITE_USER: 'Onsite User',
    OPERATIONS_MANAGER: 'Operations Manager',
    OUTREACH_MANAGER: 'Outreach Manager',
    OUTREACH_USER: 'Outreach User',
    SYSTEM_CUSTOMIZER: 'System Customizer',
}

export const CAN_EDIT_CLOSED_PURCHASE_ORDER = [
        USER_ROLES.ADMIN,
        USER_ROLES.OUTREACH_MANAGER,
        USER_ROLES.ONSITE_MANAGER,
    ];

export const BULK_EDIT_ALLOWED = [USER_ROLES.AFFILIATE_MANAGER, USER_ROLES.ADMIN];
