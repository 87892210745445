import { useEffect, useRef } from 'react';
import {
  useLogin,
  Login,
  useNotify,
  useGetList,
  useStore,
  Loading,
} from 'react-admin';
import logo from '../../layout/images/adzz-logo.png';
import { Box, Button } from '@mui/material';
import config from '../../config/config.json';
import { IRolesConfig, IUserRole } from '../../models/role-model';
import { useGetActiveUsers } from '../../hooks/useGetActiveUsers';

const { googleClientId } = config;
const GoogleLogin = () => {
  const login = useLogin();
  const notify = useNotify();
  const { data: roles, isLoading: rolesLoading } =
    useGetList('v1_Configurations');
  const { data: usersRoles, isLoading: usersRolesLoading } =
    useGetList<IUserRole>('v1_Roles');
  const { data: users, isLoading: usersLoading } = useGetActiveUsers();
  const [userId, setUserId] = useStore('userId', 0);
  const [userEmail, setUserEmail] = useStore('userEmail', '');
  const [userPermissions, setUserPermissions] = useStore('userPermissions', {});
  const rolesRef = useRef<any>([]);
  const activeUsersRef = useRef<any>([]);

  useEffect(() => {
    const handleCallBackResponse = async (response: any) => {
      rolesRef.current = (roles as IRolesConfig[])
        ?.filter((r) => r.name === 'crm')
        .map((r) => r.config);
      activeUsersRef.current = users;
      try {
        const user = await login({
          token: response.credential,
          allRoles: rolesRef.current[0],
          activeUsers: activeUsersRef.current,
          usersRoles,
        });
        setUserId(user.id);
        setUserEmail(user.email);
        setUserPermissions(user.permissions);
      } catch (error: any) {
        notify(error ? error.toString() : 'An error occurred', {
          type: 'warning',
          autoHideDuration: 1000,
        });
      }
    };
    // eslint-disable-next-line
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: googleClientId,
      callback: handleCallBackResponse,
    });
    // eslint-disable-next-line
    // @ts-ignore
    google.accounts.id.renderButton(document.getElementById('signInDiv'), {
      theme: 'outline',
      size: 'large',
    });
  }, [
    roles,
    users,
    notify,
    login,
    setUserId,
    setUserPermissions,
    setUserEmail,
    usersRoles,
  ]);
  return (
    <Login>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        paddingBottom="30px"
      >
        <img src={logo} alt="logo" style={{ maxWidth: 150 }} />
        {usersLoading || rolesLoading || usersRolesLoading ? (
          <Loading sx={{ mt: '20px' }} />
        ) : (
          <Button
            style={{ marginTop: 10 }}
            size="large"
            id="signInDiv"
          ></Button>
        )}
      </Box>
    </Login>
  );
};
export default GoogleLogin;
