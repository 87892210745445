import {
    Datagrid,
    DateField,
    EditButton,
    NumberField,
    ReferenceField,
    TextField,
} from 'react-admin';
import {UserField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';

const ContentWritingFields = () => {
    return (
        <Datagrid bulkActionButtons={false}>
            <EditButton className="MuiButton--without-label" />
            <LinkToNewTab propertyName="id" resource="v1_Posts" />
            <LinkToRecordField source="id" label="Post ID" redirectToShow={false} />
            <DateField source="ordered_on" label="Ordered On" locales="en-GB" />
            <DateField
                source="new_DateApproved"
                label="Approval Date"
                locales="en-GB"
            />
            <TextField source="new_Title" label="H1 Title" />
            <ReferenceField
                label="Campaign"
                source="campaign_id"
                reference="v1_Campaign"
                link="edit"
            >
                <TextField source="new_name" />
            </ReferenceField>
            <ReferenceField
                label="Project"
                source="campaign_id"
                reference="v1_Campaign"
                link={false}
            >
                <ReferenceField
                    reference="v1_Project"
                    source="project_id"
                    link="edit"
                >
                    <TextField source="new_name" />
                </ReferenceField>
            </ReferenceField>
            <UserField label="Request Sent By" source="requestedby_id" />
            <UserField label="To Be Approved By" source="approver_id" />
            <ReferenceField
                label="Content Status"
                source="new_ContentOrderStatus"
                reference="v1_PostsContentStatus"
                link={false}
            >
                <TextField source="value" />
            </ReferenceField>
            <ReferenceField
                source="website_id"
                link="edit"
                label="Website"
                reference="v1_Website"
            >
                <TextField source="new_name" />
            </ReferenceField>
            <UserField label="Request Receiver" source="requestedfrom_id" />
            <ReferenceField
                source="supplier_id"
                label="Supplier"
                reference="v1_ContactBase"
                link={(record, reference) => `/${'v1_Suppliers'}/${record?.id}`}
            >
                <TextField source="FullName" />
            </ReferenceField>
            <NumberField source="new_Supplierprice" label="Supplier Price" />
        </Datagrid>
    );
};

export const contentWritingSearchFields =
    'new_Title,' +
    'new_PostID,' +
    'Supplier#FullName@_ilike,' +
    'Website#new_name@_ilike,' +
    'Campaign#new_name@_ilike,' +
    'Campaign#Project#new_name@_ilike,' +
    'Approver#name@_ilike,' +
    'Ordered_By#name@_ilike,' +
    'ContentStatus#value@_ilike';

export default ContentWritingFields;
