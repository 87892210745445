import gql from 'graphql-tag';

export const GET_LIST_REPORTS = gql`
  {
    id
    name
    metabase_id
    Report_Roles {
      Roles {
        id
        name
      }
    }
  }
`;
