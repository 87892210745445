import {useState} from 'react';
import {DataProvider, withLifecycleCallbacks} from 'react-admin';
import {addEventsForMutations} from '@react-admin/ra-audit-log';

import useHasuraDataProvider from './useHasuraDataProvider';
import {authProvider} from '../authorization/authProvider';
import {postLifecycleCallbacks} from './postLifecycleCallbacks';
import {eventsLifecycleCallbacks} from './eventsLifecycleCallbacks';
import {reportsLifecycleCallbacks} from './reportsLifecycleCallbacks';
import {shouldAudit as shouldAuditPosts} from '../components/post/shouldAudit';

const shouldAudit = (action: string, ...args: any[]): boolean => {
    const [resource] = args;
    switch (resource) {
        case 'v1_Posts':
            return shouldAuditPosts(action, ...args);
    }
    return false;
};

export const useAppDataProvider = (): DataProvider | null => {
    const [dataProvider, setDataProvider] = useState(null);

    useHasuraDataProvider(setDataProvider);

    if (!dataProvider) return null;

    // Wraps hasura dataProvider to create events on Posts mutations
    // @see https://marmelab.com/ra-enterprise/modules/ra-audit-log#client-side-tracking
    const dataProviderWithLogs = addEventsForMutations(
        dataProvider,
        authProvider,
        {name: 'v1_Events', shouldAudit}
    );

    // Wraps dataProvider with `withLifecycleCallbacks` to add business logic, callbacks separated by resource
    // @see https://marmelab.com/react-admin/withLifecycleCallbacks.html
    // @see https://marmelab.com/react-admin/withLifecycleCallbacks.html#limitations
    const dataProviderWithBusinessLogic = withLifecycleCallbacks(
        dataProviderWithLogs,
        [
            eventsLifecycleCallbacks,
            postLifecycleCallbacks,
            reportsLifecycleCallbacks,
        ]
    );
    return dataProviderWithBusinessLogic;
};
