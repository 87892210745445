import { Suspense, lazy } from 'react';
import { Loading } from 'react-admin';

const ComponentFactory = (props: any) => {
  const { path } = props;
  const DynamicComponent = lazy(() => {
    return new Promise((resolve) => {
      resolve(import(`../components/${path}`));
    });
  });
  return (
    path && (
      <Suspense fallback={<Loading />}>
        <DynamicComponent {...props} />
      </Suspense>
    )
  );
};
export default ComponentFactory;
