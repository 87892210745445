import { useGetList } from 'react-admin';

export const useGetActiveUsers = () => {
  const { data, isLoading } = useGetList('v1_SystemUserBase', {
    sort: { field: 'name', order: 'ASC' },
    pagination: { page: 1, perPage: 50 },
    filter: { status: 1 },
  });
  return { data, isLoading };
};
