import {DataProvider, UpdateManyParams, UpdateManyResult, UpdateParams, UpdateResult,} from 'react-admin';

import appQueryClient from './appQueryClient';
import {V1_Posts} from "../../generated/types";

const resource = 'v1_Posts';

export const postLifecycleCallbacks = {
    resource,

    beforeUpdate: async (params: UpdateParams<V1_Posts>, dataProvider: DataProvider) => {
        return params;
    },
    afterUpdate: async (result: UpdateResult, dataProvider: DataProvider) => {
        appQueryClient.invalidateQueries('v1_Events');
        return result;
    },

    beforeUpdateMany: async (
        params: UpdateManyParams,
        dataProvider: DataProvider
    ) => {
        return params;
    },
    afterUpdateMany: async (
        result: UpdateManyResult,
        dataProvider: DataProvider
    ) => {
        appQueryClient.invalidateQueries('v1_Events');
        return result;
    },
};

export default postLifecycleCallbacks;
