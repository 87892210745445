import {getPermissionsFromRoles} from '@react-admin/ra-rbac';
import {AuthProvider, localStorageStore} from 'react-admin';
import jwt_decode from 'jwt-decode';
import {IUserRole} from '../models/role-model';
const localStore = localStorageStore();
export const authProvider: AuthProvider = {
  login: async ({token, allRoles, activeUsers, usersRoles}) => {
    try {
      const userObject = jwt_decode(token) as any;
      if (!userObject) return Promise.reject({message: 'Invalid Token'});
      const {email} = userObject;
      if (!email) {
        return Promise.reject({message: 'Email not provided'});
      }
      const user = activeUsers.find((u: any) => u.email === email);
      if (!user) {
        return Promise.reject('User is not valid');
      }
      const userRoles = (usersRoles as IUserRole[])
        .filter((u) => u.Users_Roles.some((r) => r.user_id === user.id))
        .map((u) => u.name);
      localStore.setItem('userRoles', userRoles);
      const permissions = getPermissionsFromRoles({
        roleDefinitions: allRoles,
        userRoles: userRoles,
      });
      localStorage.setItem('token', token);
      return Promise.resolve({
        id: user.id,
        permissions,
        email,
      });
    } catch (error) {
      return Promise.reject('An error occurred');
    }
  },
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem('token') as string;
    try {
      const userObject = jwt_decode(token) as any;
      if (!userObject) return Promise.reject({message: 'Invalid Token'});
      const {exp} = userObject;
      if (Date.now() / 1000 > exp + 28800)
        return Promise.reject('adzz.auth.token_expired');
    } catch (error) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const token = localStorage.getItem('token') as string;
      const userObject = jwt_decode(token) as any;
      if (!userObject) return Promise.reject({message: 'Invalid Token'});
      const fullName = userObject['name'];
      const avatar = userObject['picture'];
      const id = localStore.getItem('userId');
      return Promise.resolve({
        id,
        fullName,
        avatar,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getPermissions: () => {
    const permissions = localStore.getItem('userPermissions');
    if (!permissions) return Promise.reject('No permissions');
    return Promise.resolve(permissions);
  },
};
