import DashboardListWrapper from '../common/dashboardListWrapper';
import DashboardCampaigns from '../generic/dashboardCampaigns';
import ContentWritingFields, {
    contentWritingSearchFields,
} from './outreachDashboardContentWritingFields';
import ReadyToPublishFields, {
    readyToPublishSearchFields,
} from './outreachDashboardReadyToPublishFields';
import SentToPublisherFields, {
    sentToPublisherSearchFields,
} from './outreachDashboardSentToPublisherFields';
import {Loading, useStore} from 'react-admin';
import useGetUserId from '../../hooks/useGetUserId';
import {IfCanAccess} from '@react-admin/ra-rbac/esm/src/IfCanAccess';
import Grid from '@mui/material/Grid';
import AutocompleteWrapper from '../common/autocompleteWrapper';
import Card from '@mui/material/Card';
import {useGetActiveUsers} from '../../hooks/useGetActiveUsers';
import {
	CONTENT_STATUS,
	OUTREACH_STATUS,
	POST_STATUS, STRATEGY_TYPE,
} from '../../config/statuses';

const OutreachDashboardList = (props: any) => {
    const userId = useGetUserId();
    const [selectedUserId, setSelectedUserId] = useStore(
        'outreachDashboardSelectedUser',
        userId
    );
    const {data: activeUsers, isLoading} = useGetActiveUsers();
    return (
        <Grid container display="flex" flexDirection="column" sx={{mt: '20px'}}>
            <Grid item alignSelf="flex-end">
                {isLoading ? (
                    <Loading />
                ) : (
                    <IfCanAccess
                        resource={'outreachDashboard'}
                        action="selectUser"
                    >
                        <AutocompleteWrapper
                            disableClear={true}
                            styles={{width: 300}}
                            selectedOption={selectedUserId}
                            label="User"
                            options={activeUsers}
                            labelProp="name"
                            onOptionSelected={setSelectedUserId}
                            isRequired={false}
                        />
                    </IfCanAccess>
                )}
            </Grid>
            <Grid item>
                <Card {...props}>
                    <DashboardListWrapper
                        cardTitle="Sent To Publisher"
                        title="Outreach Dashboard"
                        resource="v1_Posts"
                        filter={{
                            statecode: 0,
                            new_OutreachStatus:
                                OUTREACH_STATUS.SENT_TO_PUBLISHER,
                            'Campaign#Owner#id': selectedUserId,
                            'Campaign#statecode': 0,
                            'new_PostStatus@_nin': [POST_STATUS.POSTED],
                        }}
                        searchFields={sentToPublisherSearchFields}
                        sort={{field: 'new_SentToPublisherOn', order: 'DESC'}}
                    >
                        <SentToPublisherFields />
                    </DashboardListWrapper>
                    <DashboardListWrapper
                        cardTitle="Ready To Publish"
                        title=" "
                        resource="v1_Posts"
                        filter={{
                            statecode: 0,
                            new_OutreachStatus: [
                                OUTREACH_STATUS.READY_TO_PUBLISH,
                                OUTREACH_STATUS.CONTENT_WRITING,
                            ],
                            'Campaign#Owner#id': selectedUserId,
                            new_ContentOrderStatus: CONTENT_STATUS.APPROVED,
                            'Campaign#statecode': 0,
                            'new_PostStatus@_nin': [POST_STATUS.POSTED],
                        }}
                        searchFields={readyToPublishSearchFields}
                        sort={{field: 'CreatedOn', order: 'DESC'}}
                    >
                        <ReadyToPublishFields />
                    </DashboardListWrapper>
                    <DashboardListWrapper
                        cardTitle="Content Writing"
                        title=" "
                        resource="v1_Posts"
                        filter={{
                            statecode: 0,
                            new_ContentOrderStatus: [
                                CONTENT_STATUS.ORDERED,
                                CONTENT_STATUS.CONTENT_REQUEST,
                                CONTENT_STATUS.DECLINED,
                                CONTENT_STATUS.WAITING_APPROVAL,
                            ],
                            new_OutreachStatus: [
                                OUTREACH_STATUS.CONTENT_WRITING,
                            ],
                            'new_PostStatus@_nin': [
                                POST_STATUS.POOL,
                                POST_STATUS.POSTED,
	                            POST_STATUS.PLANNED
                            ],
                            'Campaign#Owner#id': selectedUserId,
                            'Campaign#statecode': 0,
                        }}
                        searchFields={contentWritingSearchFields}
                        sort={{field: 'new_RequestedOn', order: 'DESC'}}
                    >
                        <ContentWritingFields />
                    </DashboardListWrapper>
                    <DashboardCampaigns
                        cardTitle="Link Building Campaigns"
                        userId={selectedUserId}
                        strategyType={STRATEGY_TYPE.LINK_BUILDING}
                    />
                    <DashboardCampaigns
                        cardTitle="Social Campaigns"
                        userId={selectedUserId}
                        strategyType={STRATEGY_TYPE.SOCIAL}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};
export default OutreachDashboardList;
