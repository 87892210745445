import useGetPreferences from './../hooks/useGetPreferences';
const Dashboard = () => {
  useGetPreferences();

  return (
    <div>
      <h1>Welcome to Adzz CRM!</h1>
    </div>
  );
};
export default Dashboard;
