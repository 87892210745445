import {
    SelectColumnsButton,
    TopToolbar,
    CreateButton,
    ExportButton, useListContext,
} from 'react-admin';
import {getConfigurableExporter} from '../../data/configurableExporter';
import {useSavedQueryDatagridInfo} from '../post/useSavedQueryPreferences';

export const ListActions = (props: any) => {
    const {preferenceKey} = useSavedQueryDatagridInfo();
    const list = useListContext();
    return (
        <TopToolbar sx={{position: 'sticky', right: 0}}>
            {list.resource !== 'v1_Posts' &&
                <CreateButton label="create"/>
            }
            <ExportButton exporter={getConfigurableExporter(preferenceKey)} />
            <SelectColumnsButton preferenceKey={preferenceKey} />
        </TopToolbar>
    );
};
