import {FunctionField} from 'react-admin';
import Link from '@mui/material/Link/Link';
import LaunchIcon from '@mui/icons-material/Launch';

type LinkInNewTabFieldProps = {
    propertyName: string;
    resource: string;
    showPropertyName?: boolean;
    label?: string;
};

const LinkToNewTab = (props: LinkInNewTabFieldProps) => {
    const {propertyName, showPropertyName, resource} = props;
    const baseUrl = window.location.origin;

    return (
        <FunctionField
            render={function (record?: any) {
                if (!record || !record[propertyName]) return null;

                const linkTab = `${baseUrl}/#/${resource}/${record.id}`;

                return (
                    <Link
                        underline="none"
                        rel="noreferrer"
                        target="_blank"
                        href={linkTab}
                        title="Open link in a new tab"
                    >
                        {showPropertyName && record[propertyName]}
                        <LaunchIcon />
                    </Link>
                );
            }}
        />
    );
};
export default LinkToNewTab;
