export const POST_STATUS = {
    IN_PROGRESS: 100000000,
    POSTED: 100000003,
    POOL: 100000001,
    PLANNED: 100000002,
};

export const ONLINE_POST_STATUS = {
    FOUND: 100000000,
    NOT_FOUND: 100000001,
};

export const POSTS_COPY_SCAPE = {
    OK: 100000000,
    DUPLICATE_INTERNET: 100000001,
    DUPLICATE_PRIVATE_INDEX: 100000002,
    DUPLICATE_INTERNET_PRIVATE_INDEX: 100000003,
    NOT_USED: 100000004,
};

export const POSTS_LINK_STATUS = {
    OK: 100000000,
    OK_INCLUDES_NO_FOLLOW: 100000005,
    OK_MANUALLY_CHECKED: 100000006,
    PARTIALY_OK: 100000001,
    LINKS_NOT_FOUND: 100000002,
    ERROR: 100000003,
    SORCE_URL_NOT_EXISTS_404: 100000004,
}

export const CONTENT_STATUS = {
    PLANNED: 100000008,
    MERGED: 100000007,
    NO_NEED: 100000000,
    POOL: 100000001,
    ORDERED: 100000002,
    WAITING_APPROVAL: 100000003,
    DECLINED: 100000005,
    APPROVED: 100000004,
    CONTENT_REQUEST: 100000006,
};

export const OUTREACH_STATUS = {
    CONTENT_WRITING: 100000000,
    READY_TO_PUBLISH: 100000001,
    SENT_TO_PUBLISHER: 100000002,
    PUBLISHED: 100000003,
};

export const PURCHASE_ORDER_STATUS = {
    APPROVAL_REQUIRED: 1,
    TO_PAY: 100000000,
    PAID: 2,
    DECLINED: 100000001,
};

export const ORDER_TYPE = {
    LINKS_DEAL: 100000000,
    SEO_PACKAGE_DEAL: 100000001,
    AFFILIATES_PROGRAM: 100000002,
    MEDIA_DEAL: 100000003,
    MEDICAL_LEADS: 100000004,
    WEBSITE_BUILDING: 100000005,
    WEBSITE_MAINTENANCE: 100000006,
    PPC_SMM: 100000007,
    LINKS_EXCHANGE: 100000008,
    OTHER: 100000009,
    CUSTOMER_REVENUE_SHARE: 100000010,
    PARTNERSHIP: 100000011,
    AFFILIATE_DEAL: 100000012,
};

export const TRACKERS_ONLINE_STATUS = {
    NOT_AVAILABLE: 100000001,
    ONLINE: 100000000,
};

export const INVOICE_STATUS = {
    CUSTOMER_REFUSED_TO_PAY: 100000000,
    PAID: 100001,
    DRAFT: 1,
    PRODUCED_NOT_PAID: 100000001,
    CANCELLED: 100000002,
};

export const RECEIPTS_STATUS = {
    DRAFT: 1,
    PAID: 100000001,
    CANCELLED: 100000003,
    INACTIVE: 2,
    CREDIT: 100000002,
};

export const SERVICE_TYPE = {
    OTHER: 100000010,
    CONTENT: 100000000,
    GRAPHICS_DESIGN: 100000001,
    OFFICE_SOFTWARE: 100000002,
    POSTS_DEAL: 100000003,
    OUTSOURCE_SOFTWARE_PROGRAMMING: 100000009,
    IT: 100000004,
    WEBSITE_DEVELOPMENT: 100000005,
    OFFICE_MAINTENANCE: 100000007,
    DOMAINS_HOSTING: 100000008,
    CONTENT_FREELANCERS: 3,
    OUTREACH_FREELANCERS: 4,
    LINK_SUPPLIERS: 2,
    MEDIA_BUYING_PROJECT: 9,
};

export const STRATEGY_TYPE = {
    VIDEO: 100000008,
    ON_SITE_CONTENT: 100000015,
    INITIAL_WEBSITE_BUILDING_CONTENT: 100000016,
    LINK_BUILDING: 100000017,
    SOCIAL: 100000012,
    PPC: 100000018,
    OTHER_SEO: 100000019,
};

export const CONTACT_TYPE = {
    WRITER: 100000000,
    LINK_BUILDER: 100000001,
    SEO_CLIENT: 100000002,
    WEBMASTER: 100000003,
    DESIGNER: 100000004,
    LINK_BUYEr_SUPPLIER: 100000005,
    AFFILIATE_MANAGER: 100000006,
    SERVICE_PROVIDER: 100000007,
};

export const ORDER_PRIORITY = {
    HIGH: 1,
    MEDIUM: 100000000,
    LOW: 100000001,
    NOT_WORKING: 100000002,
}

export const ROLES = {
    ADMIN: 1,
    OPERATIONS_MANAGER: 2,
    OUTREACH_MANAGER: 3,
    OUTREACH_USER: 4,
    AFFILIATE_MANAGER: 5,
    AFFILIATE_USER: 6,
    ONSITE_MANAGER: 7,
    ONSITE_USER: 8,
    FINANCIAL_USER: 9,
    WRITER: 10,
    SUPPLIER: 11,
    ADVERTISER: 12,
    SYSTEM_CUSTOMIZER: 13,
}
