import * as React from 'react';
import {
    AppBar,
    ToggleThemeButton,
    useSidebarState,
    useTheme,
} from 'react-admin';
import Toolbar from '@mui/material/Toolbar';
import {Box, Tooltip, Typography} from '@mui/material';
import logo from '../layout/images/adzz-logo.png';
import {customTheme, darkTheme, THEME_COLORS} from './theme';
import styles from '../components/common/stylesCommon.module.css';

const MyAppBar = (props: any) => {
    const [open, setOpen] = useSidebarState();
    const [theme, setTheme] = useTheme();

    return (
        <AppBar
            {...props}
            sx={
                theme.palette?.mode === 'light'
                    ? {
                          color: 'white',
                          backgroundColor: THEME_COLORS.LIGHT_BLUE,
                          boxShadow: 'none',
                      }
                    : {
                          color: 'white',
                          backgroundColor: 'black',
                      }
            }
        >
            <Toolbar>
                <Tooltip title={open ? 'Close menu' : 'Open menu'}>
                    <div
                        onClick={() => setOpen(!open)}
                        className={styles.pointer}
                    >
                        <img src={logo} alt="logo" className={styles.logo} />
                    </div>
                </Tooltip>
            </Toolbar>
            <Box flex="1">
                <Typography variant="h6" id="react-admin-title"></Typography>
            </Box>
            <ToggleThemeButton lightTheme={customTheme} darkTheme={darkTheme} />
        </AppBar>
    );
};

export default MyAppBar;
