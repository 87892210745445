import {useEffect} from 'react';
import {useListController, useNotify, useUnselectAll} from 'react-admin';
import {RestrictBulkType} from '../types/restrict-bulk-type';

function hasSelectedRecordWithCondition(
    listContext: {data: any[]},
    selectedIds: string | any[],
    conditions: any[]
) {
    return listContext?.data
        ?.filter((record) => selectedIds.includes(record?.id))
        .some((record) =>
            conditions.some(
                (condition) => record[condition.fieldName] === condition.status
            )
        );
}

const useRestrictBulkSelection = ({
    conditions,
    resource,
    unselectAboveLimit = true,
    limit = 26,
    timeOut = 5000,
}: RestrictBulkType): any => {
    const listContext = useListController();
    const notify = useNotify();
    const unselectAll = useUnselectAll(`v1_${resource}`);
    useEffect(() => {
        if (unselectAboveLimit && listContext.selectedIds.length >= limit) {
            notify('Users are not authorized to select more than 25 items', {
                type: 'warning',
                autoHideDuration: timeOut,
            });
            unselectAll();
        } else if (
            conditions &&
            hasSelectedRecordWithCondition(
                listContext,
                listContext.selectedIds,
                conditions
            )
        ) {
            notify(
                'Users are not allowed to make changes in records with this status ',
                {
                    type: 'warning',
	                autoHideDuration: timeOut,
                }
            );
            unselectAll();
        }
    }, [
        conditions,
        limit,
        listContext,
        notify,
        timeOut,
        unselectAboveLimit,
        unselectAll,
    ]);
    listContext.onSelect = (ids) =>
        listContext.selectedIds.length <= limit ? ids : [];
};

export default useRestrictBulkSelection;
