import {defaultTheme} from 'react-admin';

export const THEME_COLORS = {
    BLUE: '#0085E2',
    LIGHT_BLUE: '#40A3E9',
    GRAY: '#818181',
    DARK_GRAY: '#303241',
    LIGHT_DARK_GRAY: '#414354',
    LIGHT_GRAY: '#F4F4F4',
    WHITE: '#FFF',
};

export const customTheme: any = {
    ...defaultTheme,

    palette: {
        mode: 'light',
        primary: {
            main: THEME_COLORS.BLUE,
        },
        secondary: {
            main: THEME_COLORS.BLUE,
        },
        background: {
            highlighted: {primary: '#e2f7fe', secondary: '#fff5e4'},
        },
    },

    sidebar: {
        width: 200,
        closedWidth: 0,
    },

    components: {
        ...defaultTheme.components,

        // React Admin Styles

        RaList: {
            styleOverrides: {
                root: {
                    '& .RaList-main': {
                        paddingLeft: '10px',
                    },
                    '& .MuiPaper-root': {
                        backgroundColor: THEME_COLORS.LIGHT_GRAY,
                    },
                },
            },
        },

        RaShow: {
            styleOverrides: {
                root: {
                    '& .RaShow-card': {paddingBottom: 20},
                    '& .ra-field-new_Description': {
                        overflowX: 'auto',
                        maxWidth: '60vw',
                    },
                },
            },
        },

        RaEdit: {
            styleOverrides: {
                root: {
                    '& .MuiCardContent-root': {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                },
            },
        },

        RaCreate: {
            styleOverrides: {
                root: {
                    '& .MuiCardContent-root': {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                },
            },
        },

        RaBulkActionsToolbar: {
            styleOverrides: {
                root: {
                    '& .RaBulkActionsToolbar-topToolbar': {
                        position: 'sticky',
                        right: 0,
                    },
                },
            },
        },

        RaDatagrid: {
            styleOverrides: {
                root: {
                    '& .RaDatagrid-headerCell': {
                        backgroundColor: THEME_COLORS.LIGHT_GRAY,
                        fontWeight: 600,
                    },
                    '& .RaDatagrid-thead': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / .2)',
                        clipPath: 'inset(0 0 -4px 0)',
                    },
                },
            },
        },

        RaLayout: {
            styleOverrides: {
                root: {
                    backgroundColor: THEME_COLORS.DARK_GRAY,
                    paddingLeft: '6px',
                    paddingBottom: '20px',
                    '& .css-pofitp-MuiListItem-root': {
                        padding: 0,
                    },
                    '& .RaLayout-content': {
                        backgroundColor: THEME_COLORS.LIGHT_GRAY,
                    },
                },
            },
        },

        RaMenuItem: {
            styleOverrides: {
                root: {
                    color: THEME_COLORS.WHITE,
                    paddingLeft: '2px',
                    '& .RaMenuItem-nestedList': {
                        paddingLeft: 0,
                    },
                    '& .MuiListItemButton-root .MuiListItemText-primary': {
                        fontSize: '1rem',
                        lineHeight: '1.5',
                    },
                    '& .RaMenuItem-active': {
                        color: THEME_COLORS.WHITE,
                    },
                },
            },
        },

        RaRichTextInput: {
            styleOverrides: {
                root: {
                    '& .ProseMirror': {minHeight: '300px'},
                },
            },
        },

        RaTabbedForm: {
            styleOverrides: {
                root: {
                    '& .MuiCardContent-root': {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                },
            },
        },

        // Mui Styles

        MuiCardContent: {
            styleOverrides: {
                root: {
                    backgroundColor: THEME_COLORS.LIGHT_GRAY,
                    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / .2)',
                },
            },
        },

        MuiCardHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: THEME_COLORS.LIGHT_GRAY,
                },
            },
        },

        MuiDialog: {
            styleOverrides: {
                root: {
                    '& .MuiCardContent-root': {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                },
            },
        },

        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                    RaFilterListItem: {
                        '& .selected': {
                            backgroundColor: 'rgba(0, 133, 226, 0)',
                        },
                    },
                },
            },
        },

        MuiPaper: {
            styleOverrides: {
                root: {
                    '.RaTabbedForm-root .MuiCardContent-root': {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                },
            },
        },

        MuiTableBody: {
            styleOverrides: {
                root: {
                    tr: {
                        backgroundColor: THEME_COLORS.WHITE,
                    },
                    'tr:nth-of-type(even)': {
                        backgroundColor: THEME_COLORS.LIGHT_GRAY,
                    },
                },
            },
        },
    },
};

export const darkTheme: any = {
    ...defaultTheme,

    palette: {
        ...customTheme.palette,
        background: {
            highlighted: {primary: '#03445a', secondary: '#5e3b00'},
        },
        mode: 'dark',
    },

    sidebar: {
        ...customTheme.sidebar,
    },

    components: {
        // React Admin Styles

        RaList: {
            ...customTheme.RaList,
        },

        RaShow: {
            ...customTheme.RaShow,
        },

        RaBulkActionsToolbar: {
            ...customTheme.RaBulkActionsToolbar,
        },

        RaDatagrid: {
            styleOverrides: {
                root: {
                    '& .RaDatagrid-headerCell': {
                        fontWeight: 600,
                    },
                    '& .RaDatagrid-thead': {
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / .2)',
                        clipPath: 'inset(0 0 -4px 0)',
                    },
                },
            },
        },

        RaLayout: {
            styleOverrides: {
                root: {
                    paddingLeft: '6px',
                    paddingBottom: '20px',
                    '& .css-pofitp-MuiListItem-root': {
                        padding: 0,
                    },
                },
            },
        },

        RaMenuItem: {
            styleOverrides: {
                root: {
                    paddingLeft: '2px',
                    '& .RaMenuItem-nestedList': {
                        paddingLeft: 0,
                    },
                    '& .MuiListItemButton-root .MuiListItemText-primary': {
                        fontSize: '1rem',
                        lineHeight: '1.5',
                    },
                },
            },
        },

        RaRichTextInput: {
            ...customTheme.RaRichTextInput,
        },

        // Mui Styles

        MuiCardContent: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / .2)',
                },
            },
        },

        MuiListItem: {
            ...customTheme.components.MuiListItem,
        },

        MuiTableBody: {
            styleOverrides: {
                root: {
                    tr: {
                        backgroundColor: THEME_COLORS.LIGHT_DARK_GRAY,
                    },

                    'tr:nth-child(even)': {
                        backgroundColor: THEME_COLORS.DARK_GRAY,
                    },
                },
            },
        },
    },
};
