import LaunchIcon from '@mui/icons-material/Launch';
import Link from '@mui/material/Link/Link';
import {FunctionField} from 'react-admin';

type LinkInNewWindowFieldProps = {
    label: string;
    propertyName: string;
    showPropertyName?: boolean;
};

const LinkInNewWindowField = (props: LinkInNewWindowFieldProps) => {
    const {label, propertyName, showPropertyName} = props;

    return (
        <FunctionField
            onClick={(e: {stopPropagation: () => void}) => e.stopPropagation()}
            label={label}
            render={(record?: any) => {
                if (!record || !record[propertyName]) return null;

                const linkUrl = record[propertyName].startsWith('http')
                    ? record[propertyName]
                    : `https://${record[propertyName]}`;

                return (
                    <div style={{overflowX: 'auto', maxWidth: '26.5vw'}}>
                        <Link rel="noreferrer" target="_blank" href={linkUrl}>
                            {showPropertyName && record[propertyName]}
                            <LaunchIcon />
                        </Link>
                    </div>
                );
            }}
        />
    );
};

export default LinkInNewWindowField;
