import Box from '@mui/material/Box';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fc0303',
    },
    secondary: {
      main: '#fc7703',
    },
    success: {
      main: '#128c20',
    },
  },
});

type ProgressBarWithLabelColors = 'primary' | 'secondary' | 'success';
const ProgressBarWithLabel = (props: {value: number; displayValue: string}) => {
  const {value, displayValue} = props;
  const conditions = {
    60: 'primary',
    85: 'secondary',
  };

  let modColor = '';
  Object.entries(conditions).forEach((record) => {
    if (modColor === '' && value < +record[0]) {
      modColor = record[1] as ProgressBarWithLabelColors;
    }
  });
  modColor = value > 85 ? 'success' : modColor;
  const modValue = value <= 100 ? value : 100;
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Box sx={{width: '100%', mr: 1}}>
        <ThemeProvider theme={theme}>
          <LinearProgress
            variant="determinate"
            value={modValue}
            color={modColor as ProgressBarWithLabelColors}
          />
        </ThemeProvider>
      </Box>
      <Box sx={{minWidth: 35}}>
        <Typography variant="body2" color="text.secondary">
          {displayValue}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressBarWithLabel;
