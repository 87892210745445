import {
    Datagrid,
    DateField,
    EditButton,
    NumberField,
    ReferenceField,
    TextField,
} from 'react-admin';
import {UserField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';
import LinkInNewWindowField from "../common/linkInNewWindowField";

const RequestedContentFields = () => (
    <Datagrid bulkActionButtons={false}>
        <EditButton className="MuiButton--without-label" />
        <LinkToNewTab propertyName="id" resource="v1_Posts" />
        <LinkToRecordField source="id" label="Post ID" redirectToShow={false}/>
	    <LinkInNewWindowField
		    propertyName="new_name"
		    label="Source URL"
		    showPropertyName={true}
	    />
        <DateField
            source="new_RequestedOn"
            label="Requested On"
            locales="en-GB"
        />
        <ReferenceField
            label="Campaign"
            source="campaign_id"
            reference="v1_Campaign"
            link="edit"
        >
            <TextField source="new_name" />
        </ReferenceField>
        <ReferenceField
            label="Project"
            source="campaign_id"
            reference="v1_Campaign"
            link={false}
        >
            <ReferenceField
                reference="v1_Project"
                source="project_id"
                link="edit"
            >
                <TextField source="new_name" />
            </ReferenceField>
        </ReferenceField>
        <UserField label="To Be Approved By" source="approver_id" />
        <UserField label="Request Sent By" source="requestedby_id" />
        <ReferenceField
            source="website_id"
            link="edit"
            label="Website"
            reference="v1_Website"
        >
            <TextField source="new_name" />
        </ReferenceField>
        <TextField source="requestDetails" label="Request Details" />
        <NumberField source="new_RequestedMinWords" label="Words Requested" />
    </Datagrid>
);
export const requestedContentSearchFields =
    'requestDetails,Campaign#new_name@_ilike,Campaign#Project#new_name@_ilike,Approver#name@_ilike,RequestedFrom#name@_ilike,Website#new_name@_ilike';
export default RequestedContentFields;
