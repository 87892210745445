import {
    BooleanField,
    Datagrid,
    DateField,
    EditButton,
    ReferenceField,
    TextField,
} from 'react-admin';
import {UserField} from '../common/fieldWrappers';
import LinkToNewTab from '../common/linkToNewTab';
import LinkToRecordField from '../common/linkToRecordField';
import LinkInNewWindowField from "../common/linkInNewWindowField";

const PostsToApproveFields = () => (
    <Datagrid bulkActionButtons={false}>
        <EditButton className="MuiButton--without-label" />
        <LinkToNewTab propertyName="id" resource="v1_Posts" />
        <LinkToRecordField source="id" label="Post ID" redirectToShow={false} />
	    <LinkInNewWindowField
		    propertyName="new_name"
		    label="Source URL"
		    showPropertyName={true}
	    />
        <DateField
            source="new_SenttoApproval"
            label="Sent to Approval"
            locales="en-GB"
        />
        <TextField source="new_Title" label="H1 Title" />
        <ReferenceField
            label="Campaign"
            source="campaign_id"
            reference="v1_Campaign"
            link="edit"
        >
            <TextField source="new_name" />
        </ReferenceField>
        <ReferenceField
            label="Project"
            source="campaign_id"
            reference="v1_Campaign"
            link={false}
        >
            <ReferenceField
                reference="v1_Project"
                source="project_id"
                link="edit"
            >
                <TextField source="new_name" />
            </ReferenceField>
        </ReferenceField>
        <DateField source="CreatedOn" label="Ordered On" locales="en-GB" />
        <UserField label="To Be Approved By" source="approver_id" />
        <ReferenceField
            source="writer_id"
            link="edit"
            label="Writer Assigned"
            reference="v1_ContactBase"
        >
            <TextField source="FullName" />
        </ReferenceField>
        <BooleanField source="new_UploadtoSite" label="Uploaded To Site" />
    </Datagrid>
);

export const postsToApproveSearchFields =
    'new_Title,' +
    'new_PostID,' +
    'Writer#FullName@_ilike,' +
    'Campaign#new_name@_ilike,' +
    'Campaign#Project#new_name@_ilike,' +
    'Approver#name@_ilike';

export default PostsToApproveFields;
