import {FunctionField, Link, TextField, useResourceContext} from 'react-admin';

type LinkToRecordFieldProps = {
    label: string;
    source: string;
	redirectToShow: boolean;
};
const LinkToRecordField = (props: LinkToRecordFieldProps) => {
    const resource = useResourceContext();
    const {label, source, redirectToShow} = props;
    return (
        <FunctionField
            label={label}
            render={(record?: any) => {
	            const linkTo = redirectToShow ? `${resource}/${record.id}/show` : `${resource}/${record.id}`;

	            return (
                    <Link to={`/${linkTo}`}>
                        <TextField source={source} label={label} />
                    </Link>
                );
            }}
        />
    );
};
export default LinkToRecordField;
