import { Resource } from '@react-admin/ra-rbac';
import { IResourceConfigItem } from '../models/resource-config.model';
import ComponentFactory from './componentFactory';
import { buildPath } from './helpers';

export const ResourceGenerator = (props: IResourceConfigItem) => {
  const { table, componentName, hasCreate, hasEdit, hasList, hasShow } = props;
  return (
    <Resource
      key={`${table}${componentName}`}
      name={table}
      list={
        hasList ? (
          <ComponentFactory
            key={buildPath(componentName, 'List')}
            path={buildPath(componentName, 'List')}
          />
        ) : undefined
      }
      show={
        hasShow ? (
          <ComponentFactory
            key={buildPath(componentName, 'Show')}
            path={buildPath(componentName, 'Show')}
          />
        ) : undefined
      }
      create={
        hasCreate ? (
          <ComponentFactory
            key={buildPath(componentName, 'Create')}
            path={buildPath(componentName, 'Create')}
          />
        ) : undefined
      }
      edit={
        hasEdit ? (
          <ComponentFactory
            key={buildPath(componentName, 'Edit')}
            path={buildPath(componentName, 'Edit')}
          />
        ) : undefined
      }
    />
  );
};
