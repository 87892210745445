import {AppLocationContext} from '@react-admin/ra-navigation';
import {Layout} from 'react-admin';
import MyAppBar from './appBar';
import MenuList from './menu';

const MyLayout = (props: any) => (
    <AppLocationContext>
        <Layout
            sx={{
                '& .RaLayout-contentWithSidebar': {
                    '& .MuiDrawer-root': {
                        '& .MuiPaper-root': {
                            '& .RaSidebar-fixed': {
                                position: 'inherit',
                            },
                        },
                    },
                },
            }}
            {...props}
            appBar={MyAppBar}
            menu={MenuList}
        ></Layout>
    </AppLocationContext>
);

export default MyLayout;
