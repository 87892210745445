import {GetListResult, localStorageStore} from 'react-admin';
import {userHasReportRights} from '../components/report/reportList';
import {Report} from '../types/report-type';

const localStore = localStorageStore();

export const reportsLifecycleCallbacks = {
  resource: 'v1_Report',
  afterGetList: async (result: GetListResult<Report>, dataProvider: any) => {
    const userRoles = localStore.getItem('userRoles') as string[];
    const filteredData = result.data.filter((r) =>
      userHasReportRights(
        userRoles,
        r?.Report_Roles.map((r) => r.Roles.name)
      )
    );
    const filteredResult = {
      ...result,
      data: filteredData,
    };
    return filteredResult;
  },
};

export default reportsLifecycleCallbacks;
