import { ReferenceField, TextField } from 'react-admin';
import { ReferenceProps } from '../../models/input-field.models';

export const UserField = ({ source, label }: ReferenceProps) => {
  return (
    <ReferenceField
      label={label}
      source={source}
      reference="v1_SystemUserBase"
      link="edit"
    >
      <TextField source="name" />
    </ReferenceField>
  );
};
