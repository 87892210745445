import {Admin, CustomRoutes, Loading} from 'react-admin';
import {Route} from 'react-router-dom';

import Layout from './layout/layout';
import Dashboard from './layout/dashboard';
import Notification from './layout/Notification';

import {authProvider} from './authorization/authProvider';
import {customTheme} from './layout/theme';
import GoogleLogin from './components/login/login';

import {ResourceGenerator} from './utility/resourceGenerator';
import {resourceList} from './config/resourceList';
import {initStore, store} from './data/storeWrapper';
import PmDashboardList from './components/pmDashboard/pmDashboardList';
import OutreachDashboardList from './components/outreachDashboard/outreachDashboardList';
import i18nProvider from './i18n/i18nProvider';
import {useAppDataProvider} from './data/useAppDataProvider';
import {appQueryClient} from './data/appQueryClient';

const App = () => {
  const CRMAppDataProvider = useAppDataProvider();

  if (!CRMAppDataProvider) return <Loading />;

  initStore(CRMAppDataProvider);

  return (
    <Admin
      queryClient={appQueryClient}
      theme={customTheme}
      i18nProvider={i18nProvider}
      store={store}
      authProvider={authProvider}
      dataProvider={CRMAppDataProvider}
      loginPage={GoogleLogin}
      layout={Layout}
      dashboard={Dashboard}
      notification={Notification}
    >
      {resourceList.map((r) => {
        return ResourceGenerator(r);
      })}
      <CustomRoutes>
        <Route path="/pm-dashboard" element={<PmDashboardList />} />
        <Route path="/outreach-dashboard" element={<OutreachDashboardList />} />
      </CustomRoutes>
    </Admin>
  );
};

export default App;
