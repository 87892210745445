import { useGetList, useStore, useStoreContext } from 'react-admin';
import { IEvent } from '../models/event.model';
const useGetPreferences = () => {
  const store = useStoreContext();
  const [userId, setUserId] = useStore('userId', 0);
  const { data, isLoading } = useGetList<IEvent>('v1_Preferences', {
    filter: { created_by: userId },
  });
  if (isLoading) return;
  if (data && data.length > 0) {
    data.forEach((p) => {
      store.setItem(p.path, p.preference);
    });
  }
};
export default useGetPreferences;
