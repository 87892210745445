import { GET_LIST } from 'react-admin';
import { BuildFields, buildFields } from 'ra-data-hasura';

import { GET_LIST_OFFERS } from './offers';
import { GET_LIST_REPORTS } from './reports';
import { GET_LIST_USER_ROLES } from './userRoles';

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst: any) =>
  queryAst.definitions[0].selectionSet.selections;

// An object of all the custom queries we have defined.
const CUSTOM_QUERIES: any = {
  v1_Offer: {
    [GET_LIST]: GET_LIST_OFFERS,
  },
  v1_Report: {
    [GET_LIST]: GET_LIST_REPORTS,
  },
  v1_Roles: {
    [GET_LIST]: GET_LIST_USER_ROLES,
  },
};
// Function which defines query overrides for specific resources/fetchTypes.
const customBuildFields: BuildFields = (type, fetchType) => {
  const resourceName: string = type.name;

  // First check if the resource has any custom queries defined.
  const resourceCustomQueries = CUSTOM_QUERIES[resourceName];

  // If this specific query i.e. resource and fetchType has a custom query, extract the fields from it.
  if (fetchType && resourceCustomQueries?.[fetchType]) {
    return extractFieldsFromQuery(resourceCustomQueries[fetchType]);
  }

  // No custom query defined, so use the default query fields (all, but none related/nested).
  return buildFields(type, fetchType);
};

export default customBuildFields;
